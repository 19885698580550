<template>
	<v-container fluid>
		<v-row>
			<v-col cols="3">
				<SidebarFilters
					:filter-name="filters"
					title="Website Customization"
				></SidebarFilters>
			</v-col>

			<v-col cols="9" class="view">
				<v-card>
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SidebarFilters from '@/components/SidebarFilters'

export default {
	components: {
		SidebarFilters,
	},
	data() {
		return {
			filters: [
				{ name: 'Themes', path: 'theme' },
				{ name: 'Colors', path: 'color' },
				{ name: 'Navbar', path: 'navbar' },
				{ name: 'Footer', path: 'footer' },
			],
		}
	},
}
</script>

<style scoped>
.container,
.row,
.view .v-card {
	height: 100%;
}
</style>
