<template>
	<v-card>
		<v-navigation-drawer permanent floating width="512">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title
						class="text-h6 grey--text text--darken-3"
					>
						{{ title }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense nav>
				<v-list-item
					v-for="filter in filterName"
					:key="filter.index"
					:to="filter.path"
				>
					<v-list-item-content>
						<v-list-item-title
							class="text-subtitle-2 grey--text text--darken-2"
						>
							{{ filter.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</v-card>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		filterName: {
			type: Array,
			title: {
				type: String,
				required: true,
			},
		},
	},
}
</script>
